var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <img src=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"user_image") || (depth0 != null ? lookupProperty(depth0,"user_image") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"user_image","hash":{},"data":data,"loc":{"start":{"line":8,"column":22},"end":{"line":8,"column":36}}}) : helper)))
    + "\" alt=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"user_image",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":43},"end":{"line":8,"column":76}}}))
    + "\" class=\"img-responsive pull-right\" style=\"height: 10vh;margin-right: 5px;\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <tr>\n                    <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"customer") : depth0)) != null ? lookupProperty(stack1,"isprivate") : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":23,"column":24},"end":{"line":27,"column":31}}})) != null ? stack1 : "")
    + "                    </td>\n                    <td>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"customer") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</td>\n                </tr>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                            "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"name",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":24,"column":28},"end":{"line":24,"column":55}}}))
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                            "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"company_name",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":26,"column":28},"end":{"line":26,"column":63}}}))
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <tr>\n                    <td>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"address",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":34,"column":24},"end":{"line":34,"column":54}}}))
    + "</td>\n                    <td>"
    + alias2(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"customer") : depth0)) != null ? lookupProperty(stack1,"contact_address") : stack1)) != null ? lookupProperty(stack1,"street") : stack1), depth0))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"customer") : depth0)) != null ? lookupProperty(stack1,"contact_address") : stack1)) != null ? lookupProperty(stack1,"streetnumber") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":60},"end":{"line":35,"column":154}}})) != null ? stack1 : "")
    + "</td>\n                </tr>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"customer") : depth0)) != null ? lookupProperty(stack1,"contact_address") : stack1)) != null ? lookupProperty(stack1,"streetnumber") : stack1), depth0));
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <tr>\n                    <td>"
    + alias1(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"zipcode",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":40,"column":24},"end":{"line":40,"column":54}}}))
    + "</td>\n                    <td>"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"customer") : depth0)) != null ? lookupProperty(stack1,"contact_address") : stack1)) != null ? lookupProperty(stack1,"zipcode") : stack1), depth0))
    + "</td>\n                </tr>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <tr>\n                    <td>"
    + alias1(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"city",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":46,"column":24},"end":{"line":46,"column":51}}}))
    + "</td>\n                    <td>"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"customer") : depth0)) != null ? lookupProperty(stack1,"contact_address") : stack1)) != null ? lookupProperty(stack1,"city") : stack1), depth0))
    + "</td>\n                </tr>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <tr>\n                    <td>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"country",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":52,"column":24},"end":{"line":52,"column":54}}}))
    + "</td>\n                    <td>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/country.js").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"customer") : depth0)) != null ? lookupProperty(stack1,"contact_address") : stack1)) != null ? lookupProperty(stack1,"country_iso2") : stack1),{"name":"common$country","hash":{},"data":data,"loc":{"start":{"line":53,"column":24},"end":{"line":53,"column":80}}}))
    + "</td>\n                </tr>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/email.js").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"customer") : depth0)) != null ? lookupProperty(stack1,"contact_person") : stack1)) != null ? lookupProperty(stack1,"contact_set") : stack1)) != null ? lookupProperty(stack1,"email") : stack1),{"name":"common$email","hash":{},"data":data,"loc":{"start":{"line":60,"column":24},"end":{"line":60,"column":82}}}))
    + "\n                    ";
},"19":function(container,depth0,helpers,partials,data) {
    return "-";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"customer") : depth0)) != null ? lookupProperty(stack1,"contact_person") : stack1)) != null ? lookupProperty(stack1,"contact_set") : stack1)) != null ? lookupProperty(stack1,"phone") : stack1), depth0))
    + "\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "                        -\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <tr>\n                    <td>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"birthday",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":76,"column":24},"end":{"line":76,"column":55}}}))
    + "</td>\n                    <td>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/datetime.js").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"customer") : depth0)) != null ? lookupProperty(stack1,"contact_person") : stack1)) != null ? lookupProperty(stack1,"birthdate") : stack1),"LL",{"name":"common$datetime","hash":{},"data":data,"loc":{"start":{"line":77,"column":24},"end":{"line":77,"column":82}}}))
    + "</td>\n                </tr>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <tr>\n                    <td>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"loyalty_points",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":82,"column":24},"end":{"line":82,"column":61}}}))
    + "</td>\n                    <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"customer") : depth0)) != null ? lookupProperty(stack1,"loyalty_customer") : stack1),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(30, data, 0),"data":data,"loc":{"start":{"line":84,"column":24},"end":{"line":88,"column":31}}})) != null ? stack1 : "")
    + "                    </td>\n                </tr>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"customer") : depth0)) != null ? lookupProperty(stack1,"loyalty_customer") : stack1)) != null ? lookupProperty(stack1,"point_total") : stack1), depth0))
    + "\n";
},"30":function(container,depth0,helpers,partials,data) {
    return "                            -\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"customer-screen customer-screen-information\"  data-ui=\"customer-information\">\n    <div class=\"customer-header designer-box\">\n    <span class=\"customer-name\">\n\n    </span>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"user_image") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":9,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"customer-details designer-box rounded\">\n        <table class=\"table table-striped\" data-region=\"productTable\">\n            <thead class=\"themed-background-primary\" >\n            <tr>\n                <th colspan=\"2\" scope=\"col\" class=\"text-center text-light\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"customer_information",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":16,"column":75},"end":{"line":16,"column":118}}}))
    + "</th>\n            </tr>\n            </thead>\n            <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"customer") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":31,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"customer") : depth0)) != null ? lookupProperty(stack1,"contact_address") : stack1)) != null ? lookupProperty(stack1,"street") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":12},"end":{"line":37,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"customer") : depth0)) != null ? lookupProperty(stack1,"contact_address") : stack1)) != null ? lookupProperty(stack1,"zipcode") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":12},"end":{"line":43,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"customer") : depth0)) != null ? lookupProperty(stack1,"contact_address") : stack1)) != null ? lookupProperty(stack1,"city") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":12},"end":{"line":49,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"customer") : depth0)) != null ? lookupProperty(stack1,"contact_address") : stack1)) != null ? lookupProperty(stack1,"country_iso2") : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":12},"end":{"line":55,"column":19}}})) != null ? stack1 : "")
    + "                <tr>\n                    <td>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"email",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":57,"column":24},"end":{"line":57,"column":52}}}))
    + "</td>\n                    <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"customer") : depth0)) != null ? lookupProperty(stack1,"contact_person") : stack1)) != null ? lookupProperty(stack1,"contact_set") : stack1)) != null ? lookupProperty(stack1,"email") : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":59,"column":20},"end":{"line":61,"column":36}}})) != null ? stack1 : "")
    + "\n                    </td>\n                </tr>\n                <tr>\n                    <td>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"phone_number",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":65,"column":24},"end":{"line":65,"column":59}}}))
    + "</td>\n                    <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"customer") : depth0)) != null ? lookupProperty(stack1,"contact_person") : stack1)) != null ? lookupProperty(stack1,"contact_set") : stack1)) != null ? lookupProperty(stack1,"phone") : stack1),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":67,"column":20},"end":{"line":71,"column":27}}})) != null ? stack1 : "")
    + "                </td>\n                </tr>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"customer") : depth0)) != null ? lookupProperty(stack1,"contact_person") : stack1)) != null ? lookupProperty(stack1,"birthdate") : stack1),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":12},"end":{"line":79,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"customer") : depth0)) != null ? lookupProperty(stack1,"loyalty_program_active") : stack1),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":80,"column":12},"end":{"line":91,"column":19}}})) != null ? stack1 : "")
    + "\n            </tbody>\n        </table>\n    </div>\n</div>\n";
},"useData":true});