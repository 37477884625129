var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"search-container designer-box rounded full-width\">\n    <form class=\"form\" onsubmit=\"return false;\" data-action=\"form\">\n        <div class=\"input-group\">\n            <input type=\"text\" class=\"form-control\" data-ui=\"search\" data-action=\"search\" placeholder=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"search_for_customer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":103},"end":{"line":4,"column":145}}}))
    + "\">\n            <span class=\"input-group-btn\">\n                <button class=\"btn btn-regular btn-primary\" type=\"button\" data-action=\"clear\">\n                    <i class=\"fas fa-times\"></i>\n                </button>\n\n                <button class=\"btn btn-regular btn-primary\" type=\"submit\">\n                    <i class=\"fas fa-search\"></i>\n                    "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"search_customers",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":12,"column":59}}}))
    + "\n                </button>\n                <button class=\"btn btn-regular btn-primary add\" data-action=\"add\">\n                    <i class=\"fas fa-user-plus\"></i>\n                    "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"add_customer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":16,"column":55}}}))
    + "\n                </button>\n                <button class=\"btn btn-regular btn-primary add\" data-action=\"add-company\">\n                    <i class=\"fas fa-building\"></i>\n                    "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"add_company",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":20,"column":54}}}))
    + "\n                </button>\n            </span>\n        </div>\n    </form>\n</div>\n\n<div class=\"customer-container designer-box rounded\">\n    <div class=\"loader-wrapper\"><div class=\"loader\"></div></div>\n    <div data-region=\"results\">\n        <p class=\"text-center\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"search_for_customers_using_the_search_bar",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":30,"column":31},"end":{"line":30,"column":95}}}))
    + "</p>\n    </div>\n</div>";
},"useData":true});