var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"form-group row\">\n        <label for=\"relation.business_data.name\" class=\"control-label vertical-align col-md-3\">"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"company_name",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":95},"end":{"line":3,"column":130}}}))
    + " <span class=\"text-danger\">*</span></label>\n        <div  class=\"col-md-9\" data-region=\"company-search\"></div>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"name",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":20,"column":91},"end":{"line":20,"column":118}}}));
},"5":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"contact_person",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":20,"column":126},"end":{"line":20,"column":163}}}));
},"7":function(container,depth0,helpers,partials,data) {
    return " <span class=\"text-danger\">*</span>";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"form-group row\">\n        <label for=\"birthdate.day\" class=\"control-label vertical-align col-md-3\">"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"birthdate",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":49,"column":81},"end":{"line":49,"column":113}}}))
    + "</label>\n        <div class=\"col-md-3\">\n            <select name=\"birthdate.day\" id=\"birthdate.day\" class=\"form-control\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"birthdate_days") : depth0),{"name":"each","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":16},"end":{"line":54,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n        </div>\n        <div class=\"col-md-3\">\n            <select name=\"birthdate.month\" id=\"birthdate.month\" class=\"form-control\" data-action=\"birthdate_month\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"birthdate_months") : depth0),{"name":"each","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":16},"end":{"line":61,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n        </div>\n        <div class=\"col-md-3\">\n            <select name=\"birthdate.year\" id=\"birthdate.year\" class=\"form-control\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"birthdate_years") : depth0),{"name":"each","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":16},"end":{"line":68,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n        </div>\n    </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":53,"column":35},"end":{"line":53,"column":45}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":53,"column":47},"end":{"line":53,"column":55}}}) : helper)))
    + "</option>\n";
},"12":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"iso2") || (depth0 != null ? lookupProperty(depth0,"iso2") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iso2","hash":{},"data":data,"loc":{"start":{"line":102,"column":31},"end":{"line":102,"column":39}}}) : helper)))
    + "\" "
    + ((stack1 = require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/compare.js").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"storesCountry") : depths[1]),"===",(depth0 != null ? lookupProperty(depth0,"iso2") : depth0),{"name":"common$compare","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":102,"column":41},"end":{"line":102,"column":115}}})) != null ? stack1 : "")
    + ">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":102,"column":116},"end":{"line":102,"column":124}}}) : helper)))
    + "</option>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "selected";
},"15":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"iso2") || (depth0 != null ? lookupProperty(depth0,"iso2") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iso2","hash":{},"data":data,"loc":{"start":{"line":106,"column":31},"end":{"line":106,"column":39}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":106,"column":41},"end":{"line":106,"column":49}}}) : helper)))
    + "</option>\n";
},"17":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"iso2") || (depth0 != null ? lookupProperty(depth0,"iso2") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iso2","hash":{},"data":data,"loc":{"start":{"line":113,"column":31},"end":{"line":113,"column":39}}}) : helper)))
    + "\" "
    + ((stack1 = require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/compare.js").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"usersLanguage") : depths[1]),"===",(depth0 != null ? lookupProperty(depth0,"iso2") : depth0),{"name":"common$compare","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":113,"column":41},"end":{"line":113,"column":115}}})) != null ? stack1 : "")
    + ">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":113,"column":116},"end":{"line":113,"column":124}}}) : helper)))
    + "</option>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "    <div class=\"form-group row\">\n        <label for=\"email\" class=\"control-label vertical-align col-md-3\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"coc_number",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":125,"column":73},"end":{"line":125,"column":106}}}))
    + "</label>\n        <div class=\"col-md-9\">\n            <input type=\"text\" class=\"form-control\" id=\"relation.business_data.coc_number\" name=\"relation.business_data.coc_number\" placeholder=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"coc_number",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":127,"column":145},"end":{"line":127,"column":178}}}))
    + "\">\n        </div>\n    </div>\n\n    <div class=\"form-group row\">\n        <label for=\"email\" class=\"control-label vertical-align col-md-3\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"vat_number",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":132,"column":73},"end":{"line":132,"column":106}}}))
    + "</label>\n        <div class=\"col-md-9\">\n            <input type=\"text\" class=\"form-control\" id=\"relation.business_data.vat_number\" name=\"relation.business_data.vat_number\" placeholder=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"vat_number",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":134,"column":145},"end":{"line":134,"column":178}}}))
    + "\">\n        </div>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_private") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":6,"column":11}}})) != null ? stack1 : "")
    + "\n<div class=\"form-group row\">\n    <label for=\"email\" class=\"control-label vertical-align col-md-3\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"salutation",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":69},"end":{"line":9,"column":102}}}))
    + "</label>\n    <div class=\"col-md-9\">\n        <select data-ui=\"salutation\" class=\"form-control\">\n            <option value=\"male\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"mr_dot",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":12,"column":33},"end":{"line":12,"column":62}}}))
    + "</option>\n            <option value=\"female\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"ms_dot",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":13,"column":35},"end":{"line":13,"column":64}}}))
    + "</option>\n            <option value=\"unknown\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"sirmadam",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":36},"end":{"line":14,"column":67}}}))
    + "</option>\n        </select>\n    </div>\n</div>\n\n<div class=\"form-group row\">\n    <label for=\"firstname\" class=\"control-label vertical-align col-md-3\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_private") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":20,"column":73},"end":{"line":20,"column":170}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_private") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":171},"end":{"line":20,"column":231}}})) != null ? stack1 : "")
    + "</label>\n    <div class=\"col-md-3\">\n        <input type=\"text\" data-ui=\"firstname\" class=\"form-control\" id=\"relation.contact_person.firstname\" name=\"relation.contact_person.firstname\" placeholder=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"first_name",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":22,"column":161},"end":{"line":22,"column":194}}}))
    + "\">\n        <div class=\"help-block\"></div>\n    </div>\n    <div class=\"col-md-2\">\n        <input type=\"text\" class=\"form-control\" id=\"relation.contact_person.familyname_prefix\" name=\"relation.contact_person.familyname_prefix\" placeholder=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"family_name_prefix",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":26,"column":157},"end":{"line":26,"column":198}}}))
    + "\">\n    </div>\n    <div class=\"col-md-4\">\n        <input type=\"text\" class=\"form-control\" id=\"relation.contact_person.familyname\" name=\"relation.contact_person.familyname\" placeholder=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"family_name",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":29,"column":143},"end":{"line":29,"column":177}}}))
    + "\">\n    </div>\n</div>\n\n<div class=\"form-group row\">\n    <label for=\"email\" class=\"control-label vertical-align col-md-3\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"email",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":34,"column":69},"end":{"line":34,"column":97}}}))
    + "</label>\n    <div class=\"col-md-9\">\n        <input type=\"email\" class=\"form-control\" id=\"relation.contact_set.email\" name=\"relation.contact_set.email\" placeholder=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"email_address",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":36,"column":128},"end":{"line":36,"column":164}}}))
    + "\">\n    </div>\n</div>\n\n<div class=\"form-group row\">\n    <label for=\"email\" class=\"control-label vertical-align col-md-3\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"phone",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":41,"column":69},"end":{"line":41,"column":97}}}))
    + "</label>\n    <div class=\"col-md-9\">\n        <input type=\"tel\" class=\"form-control\" id=\"relation.contact_set.phone\" name=\"relation.contact_set.phone\" placeholder=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"phone_number",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":43,"column":126},"end":{"line":43,"column":161}}}))
    + "\">\n    </div>\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_private") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":0},"end":{"line":72,"column":7}}})) != null ? stack1 : "")
    + "\n<div class=\"form-group row\">\n    <label for=\"zipcode\" class=\"control-label vertical-align col-md-3\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"address",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":75,"column":71},"end":{"line":75,"column":101}}}))
    + " <span class=\"text-danger\">*</span></label>\n    <div class=\"col-md-4\">\n        <input type=\"text\" class=\"form-control\" id=\"relation.contact_address.zipcode\" name=\"relation.contact_address.zipcode\" placeholder=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"zipcode",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":77,"column":139},"end":{"line":77,"column":169}}}))
    + "\">\n        <div class=\"help-block\"></div>\n    </div>\n    <div class=\"col-md-3\">\n        <input type=\"text\" class=\"form-control\" id=\"relation.contact_address.streetnumber\" name=\"relation.contact_address.streetnumber\" placeholder=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"street_number",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":81,"column":149},"end":{"line":81,"column":185}}}))
    + "\">\n    </div>\n    <div class=\"col-md-2\">\n        <input type=\"text\" class=\"form-control\" id=\"relation.contact_address.flatnumber\" name=\"relation.contact_address.flatnumber\" placeholder=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"flat_number",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":84,"column":145},"end":{"line":84,"column":179}}}))
    + "\">\n    </div>\n</div>\n\n<div class=\"form-group row\" style=\"margin-bottom: 5px;\">\n    <div class=\"col-md-offset-3 col-md-5\">\n        <input type=\"text\" class=\"form-control\" id=\"relation.contact_address.street\" name=\"relation.contact_address.street\" placeholder=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"street_name",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":90,"column":137},"end":{"line":90,"column":171}}}))
    + "\">\n    </div>\n    <div class=\"col-md-4\">\n        <input type=\"text\" class=\"form-control\" id=\"relation.contact_address.city\" name=\"relation.contact_address.city\" placeholder=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"city",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":93,"column":133},"end":{"line":93,"column":160}}}))
    + "\">\n    </div>\n</div>\n\n<div class=\"form-group row\">\n    <label for=\"relation.contact_address.country_iso2\" class=\"control-label vertical-align col-md-3\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"country_and_language",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":98,"column":101},"end":{"line":98,"column":144}}}))
    + "</label>\n    <div class=\"col-md-5\">\n        <select name=\"relation.contact_address.country_iso2\" id=\"relation.contact_address.country_iso2\" class=\"form-control\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"mainCountries") : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":101,"column":12},"end":{"line":103,"column":21}}})) != null ? stack1 : "")
    + "            <option disabled>-------</option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"countries") : depth0),{"name":"each","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":105,"column":12},"end":{"line":107,"column":21}}})) != null ? stack1 : "")
    + "        </select>\n    </div>\n    <div class=\"col-md-4\">\n        <select name=\"relation.language_iso2\" id=\"relation.language_iso2\" class=\"form-control\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"mainLanguages") : depth0),{"name":"each","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":112,"column":12},"end":{"line":114,"column":21}}})) != null ? stack1 : "")
    + "            <option disabled>-------</option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"languages") : depth0),{"name":"each","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":116,"column":12},"end":{"line":118,"column":21}}})) != null ? stack1 : "")
    + "        </select>\n    </div>\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_private") : depth0),{"name":"unless","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":123,"column":0},"end":{"line":137,"column":11}}})) != null ? stack1 : "")
    + "\n<div class=\"form-group row\">\n    <label for=\"firstname\" class=\"control-label vertical-align col-md-3\">\n        "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"communication",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":141,"column":8},"end":{"line":141,"column":44}}}))
    + "\n    </label>\n\n    <div class=\"col-md-9\">\n        <div class=\"checkbox\">\n            <label for=\"contact_set.allow_general_communication\">\n                <label class=\"switch switch-info\">\n                    <input type=\"checkbox\" data-checkbox=\"relation.contact_set.allow_general_communication\" id=\"contact_set.allow_general_communication\">\n                    <span></span>\n                </label>\n                <i class=\"fas fa-envelope-open communication-icon\"></i>\n                "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"general",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":152,"column":16},"end":{"line":152,"column":46}}}))
    + "\n            </label>\n        </div>\n    </div>\n\n    <div class=\"col-md-9 col-md-offset-3\">\n        <div class=\"checkbox\">\n            <label for=\"contact_set.allow_offer_communication\">\n                <label class=\"switch switch-info\">\n                    <input type=\"checkbox\" data-checkbox=\"relation.contact_set.allow_offer_communication\" id=\"contact_set.allow_offer_communication\">\n                    <span></span>\n                </label>\n                <i class=\"fas fa-bullhorn communication-icon\"></i>\n                "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"sales",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":165,"column":16},"end":{"line":165,"column":44}}}))
    + "\n            </label>\n        </div>\n    </div>\n\n    <div class=\"col-md-9 col-md-offset-3\">\n        <div class=\"checkbox\">\n            <label for=\"contact_set.allow_special_communication\">\n                <label class=\"switch switch-info\">\n                    <input type=\"checkbox\" data-checkbox=\"relation.contact_set.allow_special_communication\" id=\"contact_set.allow_special_communication\">\n                    <span></span>\n                </label>\n                <i class=\"far fa-comments communication-icon\"></i>\n                "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"special",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":178,"column":16},"end":{"line":178,"column":46}}}))
    + "\n            </label>\n        </div>\n    </div>\n\n</div>\n";
},"useData":true,"useDepths":true});