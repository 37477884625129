var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<table class=\"table table-vcenter table-striped\">\n    <thead>\n    <tr>\n        <th class=\"name\">\n            "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"name",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":39}}}))
    + "\n        </th>\n        <th class=\"address\">\n            "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"address",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":42}}}))
    + "\n        </th>\n        <th class=\"invoice text-right\">\n            "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"outstanding",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":11,"column":46}}}))
    + "\n        </th>\n    </tr>\n    </thead>\n    <tbody></tbody>\n    <tfoot>\n        <tr>\n            <td colspan=\"3\">\n                <button class=\"btn btn-info btn-block\" data-action=\"more\" data-ui=\"more\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"load_more",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":19,"column":89},"end":{"line":19,"column":121}}}))
    + "</button>\n            </td>\n        </tr>\n    </tfoot>\n</table>\n";
},"useData":true});