var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <img src=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"companyLogo") || (depth0 != null ? lookupProperty(depth0,"companyLogo") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"companyLogo","hash":{},"data":data,"loc":{"start":{"line":3,"column":18},"end":{"line":3,"column":33}}}) : helper)))
    + "\" alt=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"user_image",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":40},"end":{"line":3,"column":73}}}))
    + "\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <img src=\"./images/logo-login.png\" alt=\""
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"user_image",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":48},"end":{"line":5,"column":81}}}))
    + "\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "    <img class=\"producer-logo\" src=\"./images/logo-login.png\" alt=\"Storekeeper\">\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"customer-logo\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"companyLogo") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":6,"column":11}}})) != null ? stack1 : "")
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"companyLogo") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":0},"end":{"line":10,"column":7}}})) != null ? stack1 : "");
},"useData":true});