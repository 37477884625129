var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"block full designer-box rounded\">\n\n    <h2>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"edit_customer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":44}}}))
    + "\n\n        <button class=\"btn btn-primary btn-regular pull-right\" data-action=\"to-private\">\n            <i class=\"fa fa-user\"></i> "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"convert_to_consumer",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":39},"end":{"line":6,"column":81}}}))
    + "\n        </button>\n        <button class=\"btn btn-primary btn-regular pull-right\" data-action=\"to-business\">\n            <i class=\"fas fa-building\"></i> "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"convert_to_business",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":44},"end":{"line":9,"column":86}}}))
    + "\n        </button>\n    </h2>\n    <hr>\n\n    <form onsubmit=\"return false;\" autocomplete=\"off\" method=\"post\">\n\n        <div class=\"loader-wrapper\"><div class=\"loader\"></div></div>\n\n"
    + ((stack1 = container.invokePartial(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/shop.cash-register-retail/templates/customers/formPartial.hbs"),depth0,{"name":"modules/shop.cash-register-retail/templates/customers/formPartial","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n        <br>\n        <div class=\"form-group form-actions\">\n            <button class=\"btn btn-default btn-regular\" data-action=\"back\">\n                <i class=\"fas fa-arrow-left\" style=\"margin-right: 5px;\"></i> "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"go_back",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":23,"column":77},"end":{"line":23,"column":107}}}))
    + "\n            </button>\n            <button class=\"btn btn-primary btn-regular pull-right\" data-action=\"save\">\n                <i class=\"fas fa-save\"></i> "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"save",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":26,"column":44},"end":{"line":26,"column":71}}}))
    + "\n            </button>\n        </div>\n\n    </form>\n\n</div>\n\n<div data-region=\"popup\" class=\"hidden\"></div>\n";
},"usePartial":true,"useData":true});