var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                ("
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"fromDate") || (depth0 != null ? lookupProperty(depth0,"fromDate") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"fromDate","hash":{},"data":data,"loc":{"start":{"line":6,"column":17},"end":{"line":6,"column":29}}}) : helper)))
    + ")\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                ("
    + alias4(((helper = (helper = lookupProperty(helpers,"fromDate") || (depth0 != null ? lookupProperty(depth0,"fromDate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fromDate","hash":{},"data":data,"loc":{"start":{"line":8,"column":17},"end":{"line":8,"column":29}}}) : helper)))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"toDate") || (depth0 != null ? lookupProperty(depth0,"toDate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"toDate","hash":{},"data":data,"loc":{"start":{"line":8,"column":32},"end":{"line":8,"column":42}}}) : helper)))
    + ")\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                <button class=\"btn btn-primary btn-regular btn-lg\" id=\"cashup\"><i class=\"fa fa-money-bill-alt\"></i>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"cash_up",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":21,"column":115},"end":{"line":21,"column":145}}}))
    + "</button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                <button class=\"btn btn-primary btn-regular btn-lg\" id=\"print\"><i class=\"fa fa-print\"></i>"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"print",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":24,"column":105},"end":{"line":24,"column":133}}}))
    + "</button>\n";
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row\" data-ui=\"ticket\">\n            <div class=\"col-xs-5\">\n                <table class=\"table table-thin-border narrow-table\" style=\"margin-bottom: 0;\">\n                    <thead>\n                        <tr class=\"thin-border\">\n                            <th class=\"secondary-color\" style=\"font-size: 20px;\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"turnover",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":37,"column":81},"end":{"line":37,"column":112}}}))
    + "</th>\n                            <th class=\"text-right\" style=\"width: 33%;\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"excl_vat_turnover",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":38,"column":71},"end":{"line":38,"column":111}}}))
    + "</th>\n                            <th class=\"text-right\" style=\"width: 33%;\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"incl_vat_turnover",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":39,"column":71},"end":{"line":39,"column":111}}}))
    + "</th>\n                        </tr>\n                    </thead>\n                    <tbody>\n                        <tr class=\"no-border\">\n                            <td class=\"text-bold\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"sale",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":44,"column":50},"end":{"line":44,"column":77}}}))
    + "</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"invoiced_without_tax") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":45,"column":51},"end":{"line":45,"column":117}}}))
    + "</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"invoiced_with_tax") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":46,"column":51},"end":{"line":46,"column":114}}}))
    + "</td>\n                            <td style=\"width: 28px;\"></td>\n                        </tr>\n                        <tr class=\"no-border\">\n                            <td class=\"text-bold\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"return",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":50,"column":50},"end":{"line":50,"column":79}}}))
    + "</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"refunded_without_tax") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":51,"column":51},"end":{"line":51,"column":117}}}))
    + "</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"refunded_with_tax") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":52,"column":51},"end":{"line":52,"column":114}}}))
    + "</td>\n                            <td style=\"width: 28px;\"></td>\n                        </tr>\n                        <tr class=\"no-border\">\n                            <td></td>\n                            <td class=\"text-right thin-top-border\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"turnover_without_tax") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":57,"column":67},"end":{"line":57,"column":133}}}))
    + "</td>\n                            <td class=\"text-right thin-top-border\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"turnover_with_tax") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":58,"column":67},"end":{"line":58,"column":130}}}))
    + "</td>\n                            <td style=\"width: 28px;\"></td>\n                        </tr>\n                    </tbody>\n                </table>\n                <table class=\"table table-thin-border narrow-table\" style=\"margin-bottom: 0\">\n                    <thead>\n                        <tr class=\"thin-border\">\n                            <th class=\"secondary-color\" style=\"font-size: 20px;\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"orders",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":66,"column":81},"end":{"line":66,"column":110}}}))
    + "</th>\n                            <th class=\"text-right\" style=\"width: 33%;\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"excl_vat_turnover",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":67,"column":71},"end":{"line":67,"column":111}}}))
    + "</th>\n                            <th class=\"text-right\" style=\"width: 33%;\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"incl_vat_turnover",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":68,"column":71},"end":{"line":68,"column":111}}}))
    + "</th>\n                        </tr>\n                    </thead>\n                    <tbody>\n                        <tr class=\"no-border\">\n                            <td class=\"text-bold\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"on_order",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":73,"column":50},"end":{"line":73,"column":81}}}))
    + "</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"not_invoiced_without_tax") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":74,"column":51},"end":{"line":74,"column":121}}}))
    + "</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"not_invoiced_with_tax") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":75,"column":51},"end":{"line":75,"column":118}}}))
    + "</td>\n                            <td style=\"width: 28px;\"></td>\n                        </tr>\n                    </tbody>\n                </table>\n                <table class=\"table table-thin-border narrow-table\" style=\"margin-bottom: 0;\">\n                    <thead>\n                        <tr class=\"thin-border\">\n                            <th class=\"secondary-color\" style=\"font-size: 20px;\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"vat",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":83,"column":81},"end":{"line":83,"column":107}}}))
    + "</th>\n                            <th class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"total",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":84,"column":51},"end":{"line":84,"column":79}}}))
    + "</th>\n                        </tr>\n                    </thead>\n                    <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"taxes") : stack1),{"name":"each","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":24},"end":{"line":98,"column":33}}})) != null ? stack1 : "")
    + "                        <tr class=\"no-border\">\n                            <td></td>\n                            <td class=\"text-right thin-top-border\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"tax_total") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":101,"column":67},"end":{"line":101,"column":122}}}))
    + "</td>\n                            <td></td>\n                        </tr>\n                    </tbody>\n                </table>\n                <table class=\"table table-thin-border narrow-table\" style=\"margin-bottom: 0;\">\n                    <thead>\n                    <tr class=\"thin-border\">\n                        <th class=\"secondary-color\" style=\"font-size: 20px;\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"discount",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":109,"column":77},"end":{"line":109,"column":108}}}))
    + "</th>\n                        <th class=\"text-right exclude-colorize\" style=\"width: 33%;\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"incl_vat_turnover",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":110,"column":84},"end":{"line":110,"column":124}}}))
    + "</th>\n                        <th class=\"text-right exclude-colorize\" style=\"width: 33%;\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"percentage",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":111,"column":84},"end":{"line":111,"column":117}}}))
    + "</th>\n                    </tr>\n                    </thead>\n                    <tbody>\n                    <tr class=\"no-border\">\n                        <td class=\"text-bold\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"sale",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":116,"column":46},"end":{"line":116,"column":73}}}))
    + "</td>\n                        <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"discount_with_tax") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":117,"column":47},"end":{"line":117,"column":110}}}))
    + "</td>\n                        <td class=\"text-right\">"
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"discount_percentage") : stack1), depth0))
    + "%</td>\n                        <td style=\"width: 28px;\"></td>\n                    </tr>\n                    </tbody>\n                </table>\n            </div>\n            <div class=\"col-xs-7\">\n                <table class=\"table table-thin-border narrow-table\" style=\"margin-bottom: 0;\">\n                    <thead>\n                        <tr class=\"thin-border\">\n                            <th class=\"secondary-color\" style=\"font-size: 20px;\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"payment_method",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":128,"column":81},"end":{"line":128,"column":118}}}))
    + "</th>\n                            <th class=\"text-right\" style=\"width: 20%;\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"in",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":129,"column":71},"end":{"line":129,"column":96}}}))
    + "</th>\n                            <th class=\"text-right\" style=\"width: 20%;\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"out",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":130,"column":71},"end":{"line":130,"column":97}}}))
    + "</th>\n                            <th class=\"text-right\" style=\"width: 20%;\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"total",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":131,"column":71},"end":{"line":131,"column":99}}}))
    + "</th>\n                        </tr>\n                    </thead>\n                    <tbody>\n                        <tr class=\"no-border\">\n                            <td class=\"text-bold\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"cash",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":136,"column":50},"end":{"line":136,"column":77}}}))
    + "</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"payments") : stack1)) != null ? lookupProperty(stack1,"cash") : stack1)) != null ? lookupProperty(stack1,"in") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":137,"column":51},"end":{"line":137,"column":113}}}))
    + "</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"payments") : stack1)) != null ? lookupProperty(stack1,"cash") : stack1)) != null ? lookupProperty(stack1,"out") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":138,"column":51},"end":{"line":138,"column":114}}}))
    + "</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"payments") : stack1)) != null ? lookupProperty(stack1,"cash") : stack1)) != null ? lookupProperty(stack1,"total") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":139,"column":51},"end":{"line":139,"column":116}}}))
    + "</td>\n                            <td></td>\n                        </tr>\n                        <tr class=\"no-border\">\n                            <td class=\"text-bold\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"pincreditcard",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":143,"column":50},"end":{"line":143,"column":86}}}))
    + "</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"payments") : stack1)) != null ? lookupProperty(stack1,"terminal") : stack1)) != null ? lookupProperty(stack1,"in") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":144,"column":51},"end":{"line":144,"column":117}}}))
    + "</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"payments") : stack1)) != null ? lookupProperty(stack1,"terminal") : stack1)) != null ? lookupProperty(stack1,"out") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":145,"column":51},"end":{"line":145,"column":118}}}))
    + "</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"payments") : stack1)) != null ? lookupProperty(stack1,"terminal") : stack1)) != null ? lookupProperty(stack1,"total") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":146,"column":51},"end":{"line":146,"column":120}}}))
    + "</td>\n                            <td></td>\n                        </tr>\n                        <tr class=\"no-border\">\n                            <td class=\"text-bold\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"own_gift_card",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":150,"column":50},"end":{"line":150,"column":86}}}))
    + "</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"payments") : stack1)) != null ? lookupProperty(stack1,"gift_cards") : stack1)) != null ? lookupProperty(stack1,"in") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":151,"column":51},"end":{"line":151,"column":119}}}))
    + "</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"payments") : stack1)) != null ? lookupProperty(stack1,"gift_cards") : stack1)) != null ? lookupProperty(stack1,"out") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":152,"column":51},"end":{"line":152,"column":120}}}))
    + "</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"payments") : stack1)) != null ? lookupProperty(stack1,"gift_cards") : stack1)) != null ? lookupProperty(stack1,"total") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":153,"column":51},"end":{"line":153,"column":122}}}))
    + "</td>\n                            <td></td>\n                        </tr>\n                        <tr class=\"no-border\">\n                            <td class=\"text-bold\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"external_gift_cards",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":157,"column":50},"end":{"line":157,"column":92}}}))
    + "</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"payments") : stack1)) != null ? lookupProperty(stack1,"external_gift_cards") : stack1)) != null ? lookupProperty(stack1,"in") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":158,"column":51},"end":{"line":158,"column":128}}}))
    + "</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"payments") : stack1)) != null ? lookupProperty(stack1,"external_gift_cards") : stack1)) != null ? lookupProperty(stack1,"out") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":159,"column":51},"end":{"line":159,"column":129}}}))
    + "</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"payments") : stack1)) != null ? lookupProperty(stack1,"external_gift_cards") : stack1)) != null ? lookupProperty(stack1,"total") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":160,"column":51},"end":{"line":160,"column":131}}}))
    + "</td>\n                            <td></td>\n                        </tr>\n                        <tr class=\"no-border\">\n                            <td class=\"text-bold\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"internet_payments",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":164,"column":50},"end":{"line":164,"column":90}}}))
    + "</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"payments") : stack1)) != null ? lookupProperty(stack1,"web") : stack1)) != null ? lookupProperty(stack1,"in") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":165,"column":51},"end":{"line":165,"column":112}}}))
    + "</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"payments") : stack1)) != null ? lookupProperty(stack1,"web") : stack1)) != null ? lookupProperty(stack1,"out") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":166,"column":51},"end":{"line":166,"column":113}}}))
    + "</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"payments") : stack1)) != null ? lookupProperty(stack1,"web") : stack1)) != null ? lookupProperty(stack1,"total") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":167,"column":51},"end":{"line":167,"column":115}}}))
    + "</td>\n                            <td></td>\n                        </tr>\n                        <tr class=\"no-border\">\n                            <td class=\"text-bold\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"other",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":171,"column":50},"end":{"line":171,"column":78}}}))
    + "</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"payments") : stack1)) != null ? lookupProperty(stack1,"other") : stack1)) != null ? lookupProperty(stack1,"in") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":172,"column":51},"end":{"line":172,"column":114}}}))
    + "</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"payments") : stack1)) != null ? lookupProperty(stack1,"other") : stack1)) != null ? lookupProperty(stack1,"out") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":173,"column":51},"end":{"line":173,"column":115}}}))
    + "</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"payments") : stack1)) != null ? lookupProperty(stack1,"other") : stack1)) != null ? lookupProperty(stack1,"total") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":174,"column":51},"end":{"line":174,"column":117}}}))
    + "</td>\n                            <td style=\"width: 28px; text-align: center;\"><i class=\"fa fa-plus\"></i></td>\n                        </tr>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showLoyaltyPoints") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":177,"column":24},"end":{"line":184,"column":31}}})) != null ? stack1 : "")
    + "                        <tr class=\"no-border\">\n                            <td></td>\n                            <td class=\"text-right thin-top-border\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"payment_in") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":187,"column":67},"end":{"line":187,"column":123}}}))
    + "</td>\n                            <td class=\"text-right thin-top-border\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"payment_out") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":188,"column":67},"end":{"line":188,"column":124}}}))
    + "</td>\n                            <td class=\"text-right thin-top-border\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"payment_total") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":189,"column":67},"end":{"line":189,"column":126}}}))
    + "</td>\n                            <td></td>\n                        </tr>\n                    </tbody>\n                </table>\n                <table class=\"table table-thin-border narrow-table\" style=\"margin-bottom: 0\">\n                    <thead>\n                        <tr class=\"thin-border\">\n                            <th class=\"secondary-color\" style=\"font-size: 20px;\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"other",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":197,"column":81},"end":{"line":197,"column":109}}}))
    + "</th>\n                            <th class=\"text-right\" style=\"width: 20%;\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"in",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":198,"column":71},"end":{"line":198,"column":96}}}))
    + "</th>\n                            <th class=\"text-right\" style=\"width: 20%;\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"out",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":199,"column":71},"end":{"line":199,"column":97}}}))
    + "</th>\n                        </tr>\n                    </thead>\n                    <tbody>\n                        <tr class=\"no-border\">\n                            <td class=\"text-bold\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"cash_drawer_manual",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":204,"column":50},"end":{"line":204,"column":91}}}))
    + "</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"manual_mutation_in") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":205,"column":51},"end":{"line":205,"column":115}}}))
    + "</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"manual_mutation_out") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":206,"column":51},"end":{"line":206,"column":116}}}))
    + "</td>\n                            <td style=\"width: 28px;\"></td>\n                        </tr>\n                        <tr class=\"no-border\">\n                            <td class=\"text-bold\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"skimmed",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":210,"column":50},"end":{"line":210,"column":80}}}))
    + "</td>\n                            <td class=\"text-right\">-</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"skimmed_total") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":212,"column":51},"end":{"line":212,"column":110}}}))
    + "</td>\n                            <td style=\"width: 28px;\"></td>\n                        </tr>\n                        <tr class=\"no-border\">\n                            <td class=\"text-bold\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"emballage_turnover",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":216,"column":50},"end":{"line":216,"column":91}}}))
    + "</td>\n                            <td class=\"text-right\">-</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"emballage_out") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":218,"column":51},"end":{"line":218,"column":110}}}))
    + "</td>\n                            <td style=\"width: 28px;\"></td>\n                        </tr>\n                    </tbody>\n                </table>\n                <table class=\"table table-thin-border narrow-table\" style=\"margin-bottom: 0\">\n                    <thead>\n                        <tr class=\"thin-border\">\n                            <th class=\"secondary-color\" style=\"font-size: 20px;\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"balance",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":226,"column":81},"end":{"line":226,"column":111}}}))
    + "</th>\n                            <th class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"total",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":227,"column":51},"end":{"line":227,"column":79}}}))
    + "</th>\n                        </tr>\n                    </thead>\n                    <tbody>\n                        <tr class=\"no-border\">\n                            <td class=\"text-bold\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"cash_difference",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":232,"column":50},"end":{"line":232,"column":88}}}))
    + "</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"cash_difference_total") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":233,"column":51},"end":{"line":233,"column":118}}}))
    + "</td>\n                            <td style=\"width: 28px;\"></td>\n                        </tr>\n                        <tr class=\"no-border\">\n                            <td class=\"text-bold\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"other_difference",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":237,"column":50},"end":{"line":237,"column":89}}}))
    + "</td>\n                            <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"other_difference_total") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":238,"column":51},"end":{"line":238,"column":119}}}))
    + "</td>\n                            <td style=\"width: 28px;\"></td>\n                        </tr>\n                    </tbody>\n                </table>\n            </div>\n        </div>\n";
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <tr class=\"no-border\">\n                                    <td class=\"text-bold\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"vat",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":90,"column":58},"end":{"line":90,"column":84}}}))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":90,"column":85},"end":{"line":90,"column":93}}}) : helper)))
    + "%</td>\n                                    <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"currency_iso3") : depths[1]),depth0,{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":91,"column":59},"end":{"line":91,"column":106}}}))
    + "</td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"last")),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(13, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":92,"column":36},"end":{"line":96,"column":43}}})) != null ? stack1 : "")
    + "                                </tr>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                                        <td style=\"width: 28px; text-align: center;\"><i class=\"fa fa-plus\"></i></td>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "                                        <td></td>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <tr class=\"no-border\">\n                                <td class=\"text-bold\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"loyalty_points",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":179,"column":54},"end":{"line":179,"column":91}}}))
    + "</td>\n                                <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"payments") : stack1)) != null ? lookupProperty(stack1,"loyalty_points") : stack1)) != null ? lookupProperty(stack1,"in") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":180,"column":55},"end":{"line":180,"column":127}}}))
    + "</td>\n                                <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"payments") : stack1)) != null ? lookupProperty(stack1,"loyalty_points") : stack1)) != null ? lookupProperty(stack1,"out") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":181,"column":55},"end":{"line":181,"column":128}}}))
    + "</td>\n                                <td class=\"text-right\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"payments") : stack1)) != null ? lookupProperty(stack1,"loyalty_points") : stack1)) != null ? lookupProperty(stack1,"total") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":182,"column":55},"end":{"line":182,"column":130}}}))
    + "</td>\n                            </tr>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "        <p class=\"text-center\">"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"loading_data_dot",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":246,"column":31},"end":{"line":246,"column":70}}}))
    + "</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"content designer-box rounded\">\n    <h2>\n        <a href=\"javascript:void(0);\" id=\"day-picker\">\n            "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"daily_store_turnover",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":55}}}))
    + "\n"
    + ((stack1 = require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/compare.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"fromDate") : depth0),"===",(depth0 != null ? lookupProperty(depth0,"toDate") : depth0),{"name":"common$compare","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":9,"column":31}}})) != null ? stack1 : "")
    + "            <i class=\"fa fa-caret-down\"></i>\n        </a>\n    </h2>\n\n    <div class=\"clearfix\" style=\"margin-top: 10px;\">\n        <span class=\"pull-left\">\n            <button class=\"btn btn-default btn-regular btn-lg\" id=\"today\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"today",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":16,"column":74},"end":{"line":16,"column":102}}}))
    + "</button>\n            <button class=\"btn btn-default btn-regular btn-lg\" id=\"yesterday\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"yesterday",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":17,"column":78},"end":{"line":17,"column":110}}}))
    + "</button>\n        </span>\n        <span class=\"pull-right\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isToday") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":22,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasReceiptPrinter") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":12},"end":{"line":25,"column":19}}})) != null ? stack1 : "")
    + "        </span>\n    </div>\n\n    <hr>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"data") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.program(17, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":31,"column":4},"end":{"line":247,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true,"useDepths":true});