var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        ,\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"contact_address") : depth0)) != null ? lookupProperty(stack1,"flatnumber") : stack1), depth0));
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"outstanding_invoice_stats") : depth0)) != null ? lookupProperty(stack1,"currency_iso3") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"outstanding_invoice_stats") : depth0)) != null ? lookupProperty(stack1,"value_outstanding_wt") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":15,"column":121}}}))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "        -\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td data-customer>\n    "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/fullRelationName.js").call(alias1,depth0,{"name":"common$fullRelationName","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":33}}}))
    + "\n</td>\n<td data-customer>\n    "
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"contact_address") : depth0)) != null ? lookupProperty(stack1,"zipcode") : stack1), depth0))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"contact_address") : depth0)) != null ? lookupProperty(stack1,"zipcode") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":8,"column":11}}})) != null ? stack1 : "")
    + "    "
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"contact_address") : depth0)) != null ? lookupProperty(stack1,"street") : stack1), depth0))
    + " "
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"contact_address") : depth0)) != null ? lookupProperty(stack1,"streetnumber") : stack1), depth0))
    + "\n\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"contact_address") : depth0)) != null ? lookupProperty(stack1,"flatnumber") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":11,"column":75}}})) != null ? stack1 : "")
    + "\n</td>\n<td class=\"text-right\" data-invoice>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"outstanding_invoice_stats") : depth0)) != null ? lookupProperty(stack1,"value_outstanding_wt") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":14,"column":4},"end":{"line":18,"column":11}}})) != null ? stack1 : "")
    + "</td>\n";
},"useData":true});