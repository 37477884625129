var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                <i class=\"far fa-user-circle text-primary fa-2x customer-icon\"></i>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                <i class=\"far fa-building text-primary fa-2x customer-icon\"></i>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <tr>\n                                <td>\n                                    "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/datetime.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"date_created") : depth0),"LLL",{"name":"common$datetime","hash":{},"data":data,"loc":{"start":{"line":81,"column":36},"end":{"line":81,"column":74}}}))
    + "\n                                </td>\n                                <td class=\"text-right\">\n                                    "
    + alias2(((helper = (helper = lookupProperty(helpers,"point_change") || (depth0 != null ? lookupProperty(depth0,"point_change") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"point_change","hash":{},"data":data,"loc":{"start":{"line":84,"column":36},"end":{"line":84,"column":54}}}) : helper)))
    + "\n                                </td>\n                                <td>\n                                    <div class=\"label label-info\">"
    + alias2(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"loyalty_operation_type") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</div>\n                                    "
    + alias2(((helper = (helper = lookupProperty(helpers,"comment") || (depth0 != null ? lookupProperty(depth0,"comment") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"comment","hash":{},"data":data,"loc":{"start":{"line":88,"column":36},"end":{"line":88,"column":49}}}) : helper)))
    + "\n                                </td>\n                                <td>\n                                    "
    + alias2(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"created_by") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n                                </td>\n                                <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"order_invoice") : depth0)) != null ? lookupProperty(stack1,"order_id") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":94,"column":36},"end":{"line":98,"column":43}}})) != null ? stack1 : "")
    + "                                </td>\n                            </tr>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <a class=\"btn btn-default btn-regular\" href=\"#orders/"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"order_invoice") : depth0)) != null ? lookupProperty(stack1,"order_id") : stack1), depth0))
    + "\">\n                                            "
    + alias1(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"see_order",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":96,"column":44},"end":{"line":96,"column":76}}}))
    + "\n                                        </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"block full designer-box rounded\">\n\n    <div class=\"block-title\">\n        <h2 class=\"customer-title\">\n\n            <i class=\"far fa-arrow-alt-circle-left text-primary fa-2x back\" data-action=\"back\"></i>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isprivate") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":12,"column":19}}})) != null ? stack1 : "")
    + "\n            <span class=\"h3 text-ellipsis customer-name\">\n                "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/fullRelationName.js").call(alias1,depth0,{"name":"common$fullRelationName","hash":{},"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":15,"column":45}}}))
    + "\n            </span>\n\n            <div class=\"block-options block-option-right\">\n                <div class=\"btn-group\">\n                    <button class=\"btn btn-primary btn-regular\" data-action=\"add\">\n                        <i class=\"fa fa-plus\"></i>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"add_points",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":21,"column":50},"end":{"line":21,"column":83}}}))
    + "\n                    </button>\n                    <button class=\"btn btn-primary btn-regular\" data-action=\"remove\">\n                        <i class=\"fa fa-minus\"></i>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"useremove_points",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":24,"column":51},"end":{"line":24,"column":90}}}))
    + "\n                    </button>\n                </div>\n            </div>\n\n        </h2>\n    </div>\n\n\n    <form onsubmit=\"return false;\" method=\"post\">\n        <div class=\"form-group row\">\n            <label for=\"email\" class=\"control-label vertical-align col-md-3\">\n                "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"loyalty_points",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":36,"column":16},"end":{"line":36,"column":53}}}))
    + "\n            </label>\n            <div class=\"col-md-9 form-control-static\">\n                "
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"loyalty_customer") : depth0)) != null ? lookupProperty(stack1,"point_total") : stack1), depth0))
    + "\n            </div>\n        </div>\n        <div class=\"form-group row\">\n            <label for=\"email\" class=\"control-label vertical-align col-md-3\">\n                "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"date_joined",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":44,"column":16},"end":{"line":44,"column":50}}}))
    + "\n            </label>\n            <div class=\"col-md-9 form-control-static\">\n                "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/datetime.js").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"loyalty_customer") : depth0)) != null ? lookupProperty(stack1,"date_created") : stack1),"LL",{"name":"common$datetime","hash":{},"data":data,"loc":{"start":{"line":47,"column":16},"end":{"line":47,"column":70}}}))
    + "\n            </div>\n        </div>\n\n        <div class=\"form-group row\">\n            <label for=\"email\" class=\"control-label vertical-align col-md-3\">\n                "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"last_5_operations",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":53,"column":16},"end":{"line":53,"column":56}}}))
    + "\n            </label>\n            <div class=\"col-md-9 form-control-static\">\n                <div class=\"table-container\" style=\"position: relative;\">\n                    <table class=\"table\">\n                        <thead>\n                        <tr>\n                            <th>\n                                "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"date",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":61,"column":32},"end":{"line":61,"column":59}}}))
    + "\n                            </th>\n                            <th>\n                                "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"points",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":64,"column":32},"end":{"line":64,"column":61}}}))
    + "\n                            </th>\n                            <th>\n                                "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"reason",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":67,"column":32},"end":{"line":67,"column":61}}}))
    + "\n                            </th>\n                            <th>\n                                "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"created_by",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":70,"column":32},"end":{"line":70,"column":65}}}))
    + "\n                            </th>\n                            <th>\n                                &nbsp;\n                            </th>\n                        </tr>\n                        </thead>\n                        <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"loyaltyCustomerOperations") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":24},"end":{"line":101,"column":33}}})) != null ? stack1 : "")
    + "                        </tbody>\n                    </table>\n                </div>\n            </div>\n        </div>\n        <div class=\"form-group form-actions\">\n            <button class=\"btn btn-default btn-regular\" data-action=\"back\">\n                <i class=\"fas fa-arrow-left\" style=\"margin-right: 5px;\"></i> "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"go_back",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":109,"column":77},"end":{"line":109,"column":107}}}))
    + "\n            </button>\n        </div>\n    </form>\n\n</div>\n\n\n<div data-region=\"popup\" class=\"hidden\"></div>\n";
},"useData":true});