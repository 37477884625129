var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"dialog--header\">\n    <h2 data-ui=\"title\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"change_pernament_discount",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":72}}}))
    + "</h2>\n    <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\n</div>\n\n<div class=\"dialog--content\">\n    <div data-ui=\"message\"></div>\n\n    <form onsubmit=\"return false;\" class=\"form-bordered\">\n        <div class=\"form-group row\">\n            <label for=\"percentage\" class=\"col-md-2\">\n                "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"new_discount",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":12,"column":51}}}))
    + "\n            </label>\n            <div class=\"col-md-10\">\n                <input type=\"number\" class=\"form-control\" min=\"1\"  step=\"1\" max=\"100\"  name=\"percentage\" id=\"percentage\">\n                <div class=\"help-block-static\"></div>\n            </div>\n        </div>\n    </form>\n</div>\n\n<div class=\"dialog--footer full-child-height\">\n    <button class=\"btn btn-default btn-regular\" data-action=\"close\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"cancel",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":23,"column":68},"end":{"line":23,"column":97}}}))
    + "</button>\n    <button class=\"btn btn-success btn-regular\" data-action=\"confirm\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"save",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":24,"column":70},"end":{"line":24,"column":97}}}))
    + "</button>\n</div>\n";
},"useData":true});