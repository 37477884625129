var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"block full\">\n    <div class=\"block-title\">\n        <h2 class=\"w-100\">\n            "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"outstanding_invoices",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":55}}}))
    + ":\n            "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/fullRelationName.js").call(alias1,depth0,{"name":"common$fullRelationName","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":41}}}))
    + "\n            <span class=\"pull-right\">\n                "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"total",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":44}}}))
    + ":\n                "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"outstanding_invoice_stats") : depth0)) != null ? lookupProperty(stack1,"currency_iso3") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"outstanding_invoice_stats") : depth0)) != null ? lookupProperty(stack1,"value_outstanding_wt") : stack1),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":129}}}))
    + "\n            </span>\n        </h2>\n    </div>\n\n    <div class=\"block table-container\">\n        <div class=\"loader-wrapper\"><div class=\"loader\"></div></div>\n        <table class=\"table\">\n            <thead>\n            <tr>\n                <th>\n                    "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"invoice",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":19,"column":20},"end":{"line":19,"column":50}}}))
    + "\n                </th>\n                <th>\n                    "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"outstanding",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":22,"column":54}}}))
    + "\n                </th>\n                <th>\n                    "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"pay",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":25,"column":20},"end":{"line":25,"column":46}}}))
    + "\n                </th>\n            </tr>\n            </thead>\n            <tbody></tbody>\n        </table>\n    </div>\n\n    <br>\n\n    <button class=\"btn btn-default btn-regular\" data-action=\"back\">\n        <i class=\"fas fa-arrow-left\"></i>\n        "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"back",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":37,"column":8},"end":{"line":37,"column":35}}}))
    + "\n    </button>\n\n</div>\n";
},"useData":true});